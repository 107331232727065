import { inject, Injectable } from '@angular/core';
import type { Observable } from 'rxjs';
import { ApiV2Service } from '../../core/services/api-v2.service';
import {
  signupFormLogoTitleDataResponseSchema,
  signupFormsResponseSchema,
  type SignupFormLogoTitleDataResponse,
  type SignupFormsResponse,
} from '../models/signup.dto.models';

export interface SignupServiceModel {
  getSignupForms: (accountId: number) => Observable<SignupFormsResponse>;
  getFormBackgroundImage: (formId: string) => Observable<string | []>;
  getFormLogo: (formId: string) => Observable<string | []>;
  getFormLogoTitleData: (
    formId: string,
  ) => Observable<SignupFormLogoTitleDataResponse>;
}

@Injectable({
  providedIn: 'root',
})
export class SignupService implements SignupServiceModel {
  readonly #apiV2Service = inject(ApiV2Service);

  getSignupForms(accountId: number): Observable<SignupFormsResponse> {
    return this.#apiV2Service.get({
      request: {
        url: {
          apiName: 'synchronizationsApi',
          path: `/v1/hubspot/forms/${String(accountId)}`,
        },
      },
      response: {
        schema: signupFormsResponseSchema,
      },
    });
  }

  getFormBackgroundImage(formId: string): Observable<string | []> {
    return this.#apiV2Service.getAndReturnText({
      request: {
        url: {
          apiName: 'synchronizationsApi',
          path: '/v1/hubspot/image/forms',
          queryParameters: { formId },
        },
      },
    });
  }

  getFormLogo(formId: string): Observable<string | []> {
    return this.#apiV2Service.getAndReturnText({
      request: {
        url: {
          apiName: 'synchronizationsApi',
          path: '/v1/hubspot/logo/forms',
          queryParameters: { formId },
        },
      },
    });
  }

  getFormLogoTitleData(
    formId: string,
  ): Observable<SignupFormLogoTitleDataResponse> {
    return this.#apiV2Service.get({
      request: {
        url: {
          apiName: 'synchronizationsApi',
          path: '/v1/hubspot/titlelogodata/forms',
          queryParameters: { formId },
        },
      },
      response: {
        schema: signupFormLogoTitleDataResponseSchema,
      },
    });
  }
}
