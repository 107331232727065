import { z } from 'zod';

export const signupFormsResponseSchema = z.array(
  z.object({ id: z.string(), name: z.string() }),
);

export type SignupFormsResponse = z.infer<typeof signupFormsResponseSchema>;

export type SignupFormDTO = z.infer<typeof signupFormsResponseSchema>[number];

export const signupFormLogoTitleDataResponseSchema = z.nullable(
  z.object({
    Title: z.nullable(z.string()),
    TitleColorHex: z.nullable(z.string()),
    TitleFontSize: z.nullable(z.number()),
    LogoWidth: z.nullable(z.number()),
    LogoHeight: z.nullable(z.number()),
  }),
);

export type SignupFormLogoTitleDataResponse = z.infer<
  typeof signupFormLogoTitleDataResponseSchema
>;
