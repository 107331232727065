import { isNumber } from '../../shared/helpers/is-number/is-number.helper';
import type {
  SignupFormDTO,
  SignupFormLogoTitleDataResponse,
} from './signup.dto.models';

export type SignupForm = SignupFormDTO;

export interface SignupFormLogoTitleData {
  title: string | undefined;
  colorHex: string | undefined;
  fontSize: string | undefined;
  logoWidth: string | undefined;
  logoHeight: string | undefined;
}

export const signUpFormTitleDataDTOToSignupFormTitleData = (
  signupFormLogoTitleDataResponse: SignupFormLogoTitleDataResponse,
): SignupFormLogoTitleData => ({
  title: signupFormLogoTitleDataResponse?.Title ?? undefined,
  colorHex: signupFormLogoTitleDataResponse?.TitleColorHex ?? undefined,
  fontSize:
    isNumber(signupFormLogoTitleDataResponse?.TitleFontSize) ?
      `${signupFormLogoTitleDataResponse.TitleFontSize}px`
    : undefined,
  logoWidth:
    isNumber(signupFormLogoTitleDataResponse?.LogoWidth) ?
      `${signupFormLogoTitleDataResponse.LogoWidth}px`
    : undefined,
  logoHeight:
    isNumber(signupFormLogoTitleDataResponse?.LogoHeight) ?
      `${signupFormLogoTitleDataResponse.LogoHeight}px`
    : undefined,
});

export const signupFormDTOsToSignupForms = (
  signupFormDTOs: SignupFormDTO[],
): SignupForm[] =>
  signupFormDTOs
    .map((signupFormDTO) => ({
      ...signupFormDTO,
      name: extractFormNameSubstring(signupFormDTO.name),
    }))
    .filter((form): form is SignupForm => form.name !== undefined);

export const extractFormNameSubstring = (
  name: SignupFormDTO['name'],
): SignupForm['name'] | undefined => {
  // eslint-disable-next-line sonarjs/slow-regex -- TODO: delete the comment and fix the error
  const regex = /\[TECH] (.*?)(?= - [^-]*\([^)]*\)$)/;

  const match = regex.exec(name);

  if (match && match[1]) {
    return match[1];
  }

  return undefined;
};
