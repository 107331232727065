import { routerReducer, type RouterReducerState } from '@ngrx/router-store';
import type { ActionReducer, ActionReducerMap, MetaReducer } from '@ngrx/store';
import { logoutFail, logoutSuccess } from '../../../auth/store/actions';
import type { CountriesState } from '../states/countries.state';
import { countriesReducer } from './countries.reducer';

export interface State {
  router: RouterReducerState;
  countries: CountriesState;
}

export const reducers: ActionReducerMap<State> = {
  router: routerReducer,
  countries: countriesReducer,
};

export function logout(
  reducer: ActionReducer<unknown>,
): ActionReducer<unknown> {
  return function (state, action) {
    return reducer(
      action.type === logoutSuccess.type || action.type === logoutFail.type ?
        undefined
      : state,
      action,
    );
  };
}

export const metaReducers: MetaReducer[] = [logout];
